import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "who-made-this-site",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#who-made-this-site",
        "aria-label": "who made this site permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Who made this site?`}</h2>
    <p>{`This site was made by `}<a parentName="p" {...{
        "href": "https://www.finmoorhouse.com"
      }}>{`Fin Moorhouse`}</a>{`, at the time a Research Scholar at the `}<a parentName="p" {...{
        "href": "https://www.futureofhumanityinstitute.org/"
      }}>{`Future of Humanity Institute`}</a>{` at Oxford University.`}</p>
    <p>{`I received advice and feedback from Avital Balwit, Spencer Becker-Kahn, William MacAskill, Max Daniel, and Stephen Clare. Errors remain my own.`}</p>
    <h2 {...{
      "id": "why-does-this-site-exist",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-does-this-site-exist",
        "aria-label": "why does this site exist permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why does this site exist?`}</h2>
    <p>{`I think longtermism is a an important idea, and I hope it becomes more widely appreciated. But when I searched around for introductory resources online, I was surprised by how little already existed. In particular, existing material often assumes a familiarity with either philosophy or effective altruism; or else it talks a lot about longtermism without carefully introducing it. So I decided to make this site as a way to quickly get up to speed on what longtermism is actually about.`}</p>
    <p>{`If you have criticisms, comments or questions, you can get in touch by email at `}<strong parentName="p">{`hello `}{`[at]`}{` finmoorhouse `}{`[dot]`}{` com`}</strong>{`. I'd love to hear your thoughts.`}</p>
    <p>{`The illustration on the introduction page is a public domain image `}<a parentName="p" {...{
        "href": "https://www.flickr.com/photos/britishlibrary/11199153996"
      }}>{`provided by the British Library`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      